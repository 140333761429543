import React from 'react';
import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";
import Clients from '../components/Clients';
import Capabilities from '../components/Capabilities';
import SamplePortfolio from '../components/samplePortfolio';


const Home = () => {

    const particlesInit = async (main) => {
        await loadFull(main);
    };

    const particlesLoaded = (container) => {};


    return (
        <div>
            <section className="hero_warp">

                <Particles
                    id="tsparticles"
                    init={particlesInit}
                    loaded={particlesLoaded}
                    options={{
                        background: {
                            color: {
                                value: "#000000",
                            },
                        },
                        fpsLimit: 120,
                        interactivity: {
                            events: {
                                onClick: {
                                    enable: true,
                                    mode: "push",
                                },
                                onHover: {
                                    enable: true,
                                    mode: "repulse",
                                },
                                resize: true,
                            },
                            modes: {
                                push: {
                                    quantity: 4,
                                },
                                repulse: {
                                    distance: 200,
                                    duration: 0.4,
                                },
                            },
                        },
                        particles: {
                            color: {
                                value: "#ffffff",
                            },
                            links: {
                                color: "#ffffff",
                                distance: 150,
                                enable: true,
                                opacity: 0.5,
                                width: 1,
                            },
                            collisions: {
                                enable: true,
                            },
                            move: {
                                direction: "none",
                                enable: true,
                                outModes: {
                                    default: "bounce",
                                },
                                random: false,
                                speed: 4,
                                straight: false,
                            },
                            number: {
                                density: {
                                    enable: true,
                                    area: 800,
                                },
                                value: 80,
                            },
                            opacity: {
                                value: 0.5,
                            },
                            shape: {
                                type: "circle",
                            },
                            size: {
                                value: { min: 1, max: 5 },
                            },
                        },
                        detectRetina: true,
                    }}
                />

                <div className="container">
                    <div className="row d-flex align-items-center height_vh">
                        <div className="col-md-12 col-12">
                            <div className="banner_content">
                                <h3 className="banner_title">Meet Mike</h3>
                                <ul className="exp_list">
                                    <li className=" wow fadeInUp" data-wow-duration='1s'>Mobile apps</li>
                                    <li className=" wow fadeInUp" data-wow-duration='2s'>Web Apps</li>
                                    <li className=" wow fadeInUp" data-wow-duration='3s'>ML & AI</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <ul className="social_link">
                    <li>
                        <a href="https://www.linkedin.com/in/mondieki" aria-label="Mondieki's LinkedIn">
                            <i className="fa fa-linkedin"></i>
                            <i className="fa fa-linkedin"></i>
                        </a>
                    </li>
                    <li>
                        <a href="https://github.com/Mondieki" aria-label="Mondieki's GitHub">
                            <i className="fa fa-github"></i>
                            <i className="fa fa-github"></i>
                        </a>
                    </li>
                </ul>
                <div className="scroll_down">
                    <a href="#portfolio_warp">Scroll Down</a>
                </div>
            </section>

            <div className="height_vh dark_bg"></div>

            <section className="portfolio_warp" id="portfolio_warp">

                <div className="port_bg_text" >
                    <h1 className="bg_strock_text" data-parallax='{"x": -200}'>Portfolio</h1>
                </div>

                <SamplePortfolio />

            </section>

            <section className="about_wrap">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h2 className="about_title wow fadeInUp">About Me</h2>
                            <div className="about_content">
                                <div className="bg_text"><h1 className="bg_strock_text" data-parallax='{"x": -150}'>About</h1></div>
                                <h4 className=" wow fadeInUp" data-wow-delay="0.5s">I'm Mike, a fullstack <a href="https://www.careerexplorer.com/careers/software-engineer/">software engineer</a>, data scientist and cloud engineer based in Nairobi. <br />
                                    With a Master's degree in <a href="https://www.coventry.ac.uk/course-structure/pg/2020-21/eec/data-science-and-computational-intelligence-msc/">Data Science</a>
                                    , an undergraduate degree in <a href="https://www.strathmore.edu/course/bachelor-of-science-in-informatics-and-computer-science/">Informatics and Computer Science</a> and 8+ years of programming and building apps,
                                    I am well versed for your IT needs.

                                    My major strengths are <a href="https://www.cleart.com/what-is-cross-platform-development.html">mobile app development</a>, web design & web development, <a href="https://blog.devmountain.com/uxe-what-is-a-ux-engineer">ui/ux design</a>, desktop app development and many more. I also specialize in <a href="https://www.careerbuilder.com/advice/software-engineers-are-needed-in-every-industry">helping startups grow their businesses.</a>
                                </h4>

                            </div>
                        </div>
                    </div>
                    <div className="row download">
                        <a href="./assets/pdfs/cv.pdf" download="MikeOndieki_CV" aria-label="Mike's CV">
                            <div className="col-lg-6 col-md-6 col-sm-12 col-12 wow fadeInUp" data-wow-delay="0.2s">
                                <input type="submit" className="download_btn" value="View CV" />
                            </div>
                        </a>
                    </div>
                </div>
            </section>

            <Capabilities />
            <Clients />
        </div>

    )
}

export default Home