import React from 'react';
// import Spinner from 'react-bootstrap/Spinner';
import firebase from 'firebase/app';
import { useCollection } from 'react-firebase-hooks/firestore';

const SamplePortfolio = () => {

    const db = firebase.firestore();
    const [value, loading, error] = useCollection(
        db.collection('projects-done').limit(4),
        {
            snapshotListenOptions: { includeMetadataChanges: true },
        }
    );

    return (

        <div className="container">
            <div className="row portfolio_single_wrap">
                {loading && (
                    <div>
                        <p>Loading ...</p>
                        {/* <Spinner animation="border" /> */}
                    </div>
                )}
                {value && (
                    value.docs.map(doc => (
                        <div className="col-md-6 col-sm-12 col-xs-12 portfolio_single_item wow fadeInUp" key={doc.id}>
                            <div className="portfolio_item">
                                <div className="port_img tilt">
                                    <a href={'/project/' + doc.id}><img src={`assets/images/portfolio-covers/${doc.id}.webp`} alt={doc.data().name + ' image cover mondieki'} className="img-fluid" /></a>
                                </div>
                                <a className="exp" href={'/project/' + doc.id}><span className="exp_inner"><span className="exp_hover">Explore</span></span></a>
                                <div className="port_text">
                                    <a href={'/project/' + doc.id}><h3 className="port_title">{doc.data().name}</h3></a>
                                    <p className="catagory">- <a href={'/project/' + doc.id}>{doc.data().industry}</a></p>
                                </div>
                            </div>
                        </div>
                    ))
                )}
                {error && (
                    <p>Error ... </p>
                )}

                <div className="container">
                    <div className="row d-flex">
                        <div className="col text-right pb-3">
                            <a href='/portfolio' className="btn btn-outline-light btn-lg rounded-0" role="button" aria-pressed="true">See more</a>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default SamplePortfolio