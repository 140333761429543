import React from 'react';
import { Helmet } from "react-helmet";
import '../App.css';

const About = () => {

    return (
        <div className="app-skeleton">
            <Helmet>
                <meta charSet="utf-8" />
                <title>About | Mondieki </title>
                <link rel="canonical" href="https://mondieki.com/about" />
            </Helmet>
            <section className="about_wrap">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h2 className="about_title wow fadeInUp">Who is Mondieki?</h2>
                            <div className="about_content">
                                <div className="bg_text"><h1 className="bg_strock_text" data-parallax='{"x": -150}'>who is mondieki</h1></div>
                                <h4 className="wow fadeInUp" data-wow-delay="0.3s">I am Mike Ondieki, a fullstack <a href="https://www.careerexplorer.com/careers/software-engineer/">software engineer</a>, data scientist and cloud engineer based in Nairobi. <br />
                                    With a Master's degree in <a href="https://www.coventry.ac.uk/course-structure/pg/2020-21/eec/data-science-and-computational-intelligence-msc/">Data Science</a>
                                    , an undergraduate degree in <a href="https://www.strathmore.edu/course/bachelor-of-science-in-informatics-and-computer-science/">Informatics and Computer Science</a> and 8+ years of programming and building apps,
                                    I enjoy building IT solutions that ease the burdens of the masses and businesses.
                                </h4><br />
                                <h4 className="wow fadeInUp" data-wow-delay="0.5s">
                                    My major strengths are <a href="https://www.cleart.com/what-is-cross-platform-development.html">mobile app development</a>, web design, web development
                                    <a href="https://blog.devmountain.com/uxe-what-is-a-ux-engineer">, ui/ux design</a>, desktop app development and many more.
                                    I also specialize in <a href="https://www.careerbuilder.com/advice/software-engineers-are-needed-in-every-industry">helping startups grow their businesses.</a>
                                </h4>
                                <br />
                                <h4 className="wow fadeInUp" data-wow-delay="0.7s">
                                    I have built and shipped <a href="https://mondieki.com/portfolio">software solutions</a> in healthcare, banking, proptech, education and the media industry.
                                </h4><br />
                                <h4 className="wow fadeInUp" data-wow-delay="0.9s">
                                    I am also available for hire as
                                    a freelancer, <a href="https://www.gartner.com/en/information-technology/glossary/it-consulting">a consultant </a>, a remote software engineer and/or project lead.
                                </h4>
                            </div>
                        </div>
                    </div>
                    <div className="row download">
                        <a href="./assets/pdfs/cv.pdf" download="MikeOndieki_CV" aria-label="Mike's CV">
                            <div className="col-lg-6 col-md-6 col-sm-12 col-12 wow fadeInUp" data-wow-delay="0.2s">
                                <input type="submit" className="download_btn" value="View CV" />
                            </div>
                        </a>
                    </div>
                </div>
            </section>
        </div>
    )

}

export default About