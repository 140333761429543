import React, { useEffect } from 'react';
import ReactGA from 'react-ga';

import './assets/bootstrap.css';


import { Route, Switch } from 'react-router-dom';
import Home from './pages/Home';
import Contact from './pages/Contact';
import Portfolio from './pages/Portfolio';
import Project from './pages/Project';
import About from './pages/About';
import TechStack from './pages/TechStack';
import NotFound from './components/404';

import firebase from 'firebase/app';
import 'firebase/firestore';



firebase.initializeApp({
  apiKey: "AIzaSyBzYzfFpujxPeqG4l0hhDt5yI25Vx_Y-qY",
  authDomain: "portfolio-ac6e1.firebaseapp.com",
  databaseURL: "https://portfolio-ac6e1.firebaseio.com",
  projectId: "portfolio-ac6e1",
  storageBucket: "portfolio-ac6e1.appspot.com",
  messagingSenderId: "634719956875",
  appId: "1:634719956875:web:20b7dc80d97ca216cf498e",
  measurementId: "G-H75LSEKJBY"
});

ReactGA.initialize('UA-180553692-1');


function App() {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);
  return (
    <main>
      <Switch>
        <Route path="/" component={Home} exact />
        <Route path="/portfolio" component={Portfolio} exact />
        <Route path="/contact" component={Contact} exact />
        <Route path="/project/:id" component={Project} exact />
        <Route path="/about" component={About} exact/>
        <Route path="/tech-stack" component={TechStack} exact/>
        <Route component={NotFound}/>
      </Switch>
      </main>
  );
  
}

export default App;
