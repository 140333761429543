import React from 'react';
import './404.css';

const NotFound = () => {


    return(
        <div id="notfound">
            <div className="notfound">
                <div className="notfound-404"></div>
                <h1>404</h1>
                <h2>Oops! Page Not Found</h2>
                <p>
                    Sorry but the page you are looking for does not exist, has been removed, is temporarily unavailable or is still in quarantine.
                </p>
                <a href="/">Back to Home</a>
            </div>
        </div>
    )

}

export default NotFound