import React from 'react';

const Capabilities = () => {


    return (

        <section className="capabiliti_wrap">
            <div className="bg_text" >
                <h1 className="bg_strock_text" data-parallax='{"x": 200}'>Capabilties</h1>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 col-12">
                        <h2 className="capabiliti_title wow fadeInUp">My Capabilities</h2>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-5 col-md-5 col-sm-12 col-12">
                        <ul className="nav nav-tabs capabiliti_tab">
                            <li className="nav-item wow fadeInUp">
                                <a className="nav-link active" data-toggle="tab" href="#webDesign">Web Design</a>
                            </li>
                            <li className="nav-item wow fadeInUp">
                                <a className="nav-link" data-toggle="tab" href="#webDev">Web Development</a>
                            </li>
                            <li className="nav-item wow fadeInUp">
                                <a className="nav-link" data-toggle="tab" href="#mobileDev">Mobile App Development</a>
                            </li>
                            <li className="nav-item wow fadeInUp">
                                <a className="nav-link" data-toggle="tab" href="#uiux">UI/UX Design</a>
                            </li>
                            <li className="nav-item wow fadeInUp">
                                <a className="nav-link" data-toggle="tab" href="#dataSci">Data Science/AI(ML)</a>
                            </li>
                            <li className="nav-item wow fadeInUp">
                                <a className="nav-link" data-toggle="tab" href="#techCons">Tech Consultant</a>
                            </li>
                        </ul>
                    </div>
                    <div className="col-lg-7 col-md-7 col-sm-12 col-12">
                        <div className="tab-content capabiliti_tab_content">
                            <div className="tab-pane fade show active" id="webDesign">
                                <p className="tabPara">
                                    One of the worst things in the world for a business is an unattractive, stale web presence. Now more than ever, an appealing web presence is imperative. A poorly designed website reflects badly on a company, making them come off as stale or out of touch.
                                </p>

                                <p className="tabPara">
                                    I have worked in redesigning and creating aesthetic sites (as evident with what you are looking at right now). Please feel free to reach out to me if I can be of service to you.
                                </p>

                            </div>

                            <div className="tab-pane fade" id="webDev">
                                <p className="tabPara">
                                    Living in the digital world, a website is crucial for any business. The web has a far more extensive reach than any other form of advertising. Your website will be the center of your profile and/or company's online presence.
                                </p>

                                <p className="tabPara">
                                    With 8+ years of web development and competencies in the popular frameworks(Angular, React, just to name a few ), I get the job done.
                                </p>

                            </div>

                            <div className="tab-pane fade" id="mobileDev">
                                <p className="tabPara">
                                    Mobile apps offer a more efficient channel for your customers to get in touch and engage with your brand. From simplifying online purchases to providing easy-to-access information, mobile apps can significantly improve your customer's experience and satisfaction.
                                </p>

                                <p className="tabPara">
                                    I develop both iOS and Android applications, consistently adhering to industry norms and ensuring they remain current within the continuously evolving technology landscape.
                                </p>

                            </div>

                            <div className="tab-pane fade" id="uiux">
                                <p className="tabPara">
                                    The user experience of your website and/or app is one of the most crucial components of a successful modern business. It's the first thing most people see when attempting to find information on who you are and what you are about. First impressions are lasting and difficult to reverse if executed poorly.
                                </p>

                                <p className="tabPara">
                                    I take great satisfaction in my acute attention to aesthetics. Allow me to assist you in achieving your maximum potential.
                                </p>

                            </div>

                            <div className="tab-pane fade" id="dataSci">
                                <p className="tabPara">
                                    It is a universal truth that modern businesses are awash with data.
                                </p>

                                <p className="tabPara">
                                    As a data scientist, it is my role to transform organisations from reactive environments with static and aged data, to automated ones that continuously learn in real time.
                                </p>

                            </div>

                            <div className="tab-pane fade" id="techCons">
                                <p className="tabPara">
                                    Leveraging years of experience and deep industry insights from developing and implementing advanced technologies, I'm equipped to revolutionize your technological usage. This includes enhancing your business operations, cutting down costs, optimizing utilization of tech possibilities, and beyond.
                                </p>

                            </div>

                        </div>
                    </div>
                </div>

            </div>
        </section>


    )
}

export default Capabilities