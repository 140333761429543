import React from 'react';

const Clients = () => {


    const clientLogo = {
        width: "160px",
        height: "120px"
    }

    return (
        <section className="client_wrap">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                        <div className="client_single_item wow fadeInUp">
                            <a href="https://aroha.health/" target="_blank" rel="noopener noreferrer">
                                <img src="assets/images/client/aroha.webp" alt="aroha cancer centre logo"
                                    style={clientLogo}/>
                            </a>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                        <div className="client_single_item wow fadeInUp">
                            <a href="https://fondieki.com/" target="_blank" rel="noopener noreferrer">
                                <img src="assets/images/client/trl.webp" alt="the royalty label logo"
                                    style={clientLogo}/>
                            </a>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                        <div className="client_single_item wow fadeInUp">
                            <a href="https://africare.health/" target="_blank" rel="noopener noreferrer">
                                <img src="assets/images/client/africare.webp" alt="africare logo"
                                    style={clientLogo}/>
                            </a>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                        <div className="client_single_item wow fadeInUp">
                            <a href="https://www.pathologynetwork.africa/" target="_blank" rel="noopener noreferrer">
                                <img src="assets/images/client/tpn.webp" alt="tpn logo"
                                    style={clientLogo}/>
                            </a>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                        <div className="client_single_item wow fadeInUp">
                            <a href="https://pangoni.io/" target="_blank" rel="noopener noreferrer">
                                <img src="assets/images/client/pangoni.webp" alt="pangoni logo"
                                    style={clientLogo}/>
                            </a>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                        <div className="client_single_item wow fadeInUp">
                            <a href="https://blubinder.com/" target="_blank" rel="noopener noreferrer">
                                <img src="assets/images/client/blubinder.webp" alt="blubinder logo"
                                    style={clientLogo}/>
                            </a>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                        <div className="client_single_item wow fadeInUp">
                            <a href="https://jakes.beauty/" target="_blank" rel="noopener noreferrer">
                                <img src="assets/images/client/jakes.webp" alt="jakes executive logo"
                                    style={clientLogo}/>
                            </a>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                        <div className="client_single_item wow fadeInUp">
                            <a href="https://suwavy.com/" target="_blank" rel="noopener noreferrer">
                                <img src="assets/images/client/suwavy.webp" alt="suwavy logo"
                                    style={clientLogo}/>
                            </a>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                        <div className="client_single_item wow fadeInUp">
                            <a href="https://www.cusu.org/" target="_blank" rel="noopener noreferrer">
                                <img src="assets/images/client/cusu.webp" alt="cusu logo"
                                    style={clientLogo}/>
                            </a>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                        <div className="client_single_item wow fadeInUp">
                            <a href="https://m.twaa.io/" target="_blank" rel="noopener noreferrer">
                                <img src="assets/images/client/twaa.webp" alt="twaa logo"
                                    style={clientLogo}/>
                            </a>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                        <div className="client_single_item wow fadeInUp">
                            <a href="https://korconnection.com/" target="_blank" rel="noopener noreferrer">
                                <img src="assets/images/client/kor.webp" alt="kor logo"
                                    style={clientLogo}/>
                            </a>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                        <div className="client_single_item wow fadeInUp">
                            <a href="https://mentorgram.org/" target="_blank" rel="noopener noreferrer">
                                <img src="assets/images/client/mentorgram.webp" alt="mentorgram logo"
                                    style={clientLogo}/>
                            </a>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                        <div className="client_single_item wow fadeInUp">
                            <a href="https://andela.com/" target="_blank" rel="noopener noreferrer">
                                <img src="assets/images/client/andela.webp" alt="andela logo"
                                    style={clientLogo}/>
                            </a>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                        <div className="client_single_item wow fadeInUp">
                            <a href="https://houseofagani.com/" target="_blank" rel="noopener noreferrer">
                                <img src="assets/images/client/agani.webp" alt="antler logo"
                                    style={clientLogo}/>
                            </a>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                        <div className="client_single_item wow fadeInUp">
                            <a href="https://www.adanianlabs.io" target="_blank" rel="noopener noreferrer">
                                <img src="assets/images/client/adalabs.webp" alt="adalabs logo"
                                    style={clientLogo}/>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Clients