import React from 'react';
import firebase from 'firebase/app';
import { useCollection } from 'react-firebase-hooks/firestore';

const PortfolioList = () => {

    const db = firebase.firestore();
    const [value, loading, error] = useCollection(
        db.collection('projects-done'),
        {
          snapshotListenOptions: { includeMetadataChanges: true },
        }
    );

    return (

        <section className="portfolio_warp portfolio_3_warp" id="portfolio_warp">
            <div className="port_bg_text" >
                <h1 className="bg_strock_text" data-parallax='{"x": -200}'>Portfolio</h1>
            </div> 
            <div className="container-fluid">
                {loading && (
                    <div className="row portfolio_single_wrap portfolio_3_column">
                        <div className="port_text">
                            <p className="text-light">Fetching Mike Ondieki's portfolio ...</p>
                        </div>
                    </div>
                )}
                {value && (
                    <div className="row portfolio_single_wrap portfolio_3_column">
                        {value.docs.map(doc => (
                            <div className="col-md-4 col-sm-12 col-xs-12 portfolio_single_item wow fadeInUp" key={doc.id}>
                                <div className="portfolio_item">
                                    <div className="port_img tilt">
                                        <a href={'/project/' +doc.id}>
                                            <img src={`assets/images/portfolio-covers/${doc.id}.webp`} alt={doc.data().name + ' image cover'} className="img-fluid"/>
                                        </a>
                                    </div>
                                    <a  className="exp" href={'/project/' +doc.id}><span className="exp_inner"><span className="exp_hover">Explore</span></span></a>
                                    <div className="port_text">
                                        <a href={'/project/' +doc.id}><h3 className="port_title">{doc.data().name} <br/></h3></a>
                                        <p className="catagory">- <a href={'/project/' +doc.id}>{doc.data().industry}</a></p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                )}
                {error && (
                    <p>Error...</p>
                )}
            </div>
        </section>

        
    )
}

export default PortfolioList