import React from 'react';

const TechStack = () => {

    return (
        <div>
            <p>TECH STACK</p>
        </div>
    )

}

export default TechStack