import React from 'react';
import { Helmet } from "react-helmet";
import { useDocument } from 'react-firebase-hooks/firestore';
import firestore from 'firebase/app';
import NotFound from '../components/404';
import parse from 'html-react-parser';
import Quill from 'quill';


const Project = (props) => {

    const [value, loading, error] = useDocument(
        firestore.firestore().doc(`projects-done/${props.match.params.id}`),
        {
          snapshotListenOptions: { includeMetadataChanges: true },
        }
    );

    const deltaToHTML = (inputDelta) => {
        var quill = new Quill(document.createElement("div"));
        quill.setContents(inputDelta);
        return quill.root.innerHTML;
    }
    
    return (
        <div>
            {loading && (
                <div>
                    <Helmet>
                        <meta charSet="utf-8" />
                        <title>{props.match.params.id} | Mondieki </title>
                        <link rel="canonical" href={`https://mondieki.com/project/${props.match.params.id}`}/>
                    </Helmet>
                    <div className="fetching-info">
                        <p>Fetching details ... </p>
                    </div>
                </div>
            )}
            {value && (
                value.exists ? 
                <div>
                    <Helmet>
                        <meta charSet="utf-8" />
                        <title>{value.data().name} | Mondieki </title>
                        <link rel="canonical" href={`https://mondieki.com/project/${value.id}`}/>
                    </Helmet>
                    <section className="hero_warp inner_banner portfolio_banner">
                        <div className="container">
                            <div className="row d-flex align-items-center">
                                <div className="col-md-12 col-12">
                                    <div className="banner_content">
                                        <h1 className="banner_title">{value.data().name}</h1>
                                        <p className="banner_para wow fadeInUp">Done for {value.data().employer}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <ul className="social_link">
                            <li>
                                <a href="https://www.linkedin.com/in/mondieki/" aria-label="Mondieki's LinkedIn">
                                    <i className="fa fa-linkedin"></i>
                                    <i className="fa fa-linkedin"></i>
                                </a>
                            </li>
                            <li>
                                <a href="https://github.com/Mondieki" aria-label="Mondieki's GitHub">
                                    <i className="fa fa-github"></i>
                                    <i className="fa fa-github"></i>
                                </a>
                            </li>
                        </ul>
                    </section>

                    <section className="portfolio_single_content">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg col-sm-12 col-12">
                                    <div className="project_summary">
                                        <h3 className="portfolio_single_title">Project Summary</h3>
                                        <div className="row">
                                            <div className="col-md-6 col-sm-12 col-12">
                                                <ul className="project_summary_list">
                                                    <li><b>Client:</b>  {value.data().employer}</li>
                                                    <li><b>Industry:</b>  <span style={{textTransform: 'capitalize'}}>{value.data().industry}</span></li>
                                                    <li><b>Core tasks:</b>  {value.data().tasks}</li>
                                                </ul>
                                            </div>
                                            <div className="col-md-6 col-sm-12 col-12">
                                                <ul className="project_summary_list">
                                                    <li><b>Appointed date:</b>  {value.data().startDate}</li>
                                                    <li><b>Completion:</b>  {value.data().endDate}</li>
                                                    <li className="project-url"><b>Website:</b>  <a href={value.data().hyperlink} target="_blank" rel="noopener noreferrer">
                                                        <span> {value.data().website}</span><span> {value.data().website}</span></a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-12 col-md-12 col-12 single_body">
                                    {value.data().info ? 
                                        parse(deltaToHTML(value.data().info)) : 
                                        <p className="portfolio_para">
                                            The details of this project cannot be disclosed without any authorization from my client. I fully respect their wishes and aim to honour our agreement.
                                        </p>
                                    }
                                    
                                </div>
                            </div>
                        </div>
                    </section>
                </div> : <NotFound/>
            )}
            {error && (
                <p>Error ... </p>
            )}
            

        </div>
    )
}

export default Project