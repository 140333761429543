import React from 'react';
import { Helmet } from "react-helmet";
import PortfolioList from '../components/PortfolioList';


const Portfolio = () => {
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Portfolio | Mondieki </title>
                <link rel="canonical" href="https://mondieki.com/portfolio" />
            </Helmet>
            <section className="hero_warp inner_banner portfolio_banner">
                <div className="container">
                    <div className="row d-flex align-items-center">
                        <div className="col-md-12 col-12">
                            <div className="banner_content">
                                <h1 className="banner_title">Mike's portfolio </h1>
                                <p className="banner_para wow fadeInUp">Here's what I have done.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <ul className="social_link">
                    <li>
                        <a href="https://www.linkedin.com/in/mondieki" aria-label="Mondieki's LinkedIn">
                            <i className="fa fa-linkedin"></i>
                            <i className="fa fa-linkedin"></i>
                        </a>
                    </li>
                    <li>
                        <a href="https://github.com/Mondieki" aria-label="Mondieki's GitHub">
                            <i className="fa fa-github"></i>
                            <i className="fa fa-github"></i>
                        </a>
                    </li>
                </ul>
            </section>

            <PortfolioList/>
        </div>
    )
}

export default Portfolio