import React, { useState } from 'react';
import { Helmet } from "react-helmet";
import firebase from 'firebase/app';

const Contact = () => {

    const [ formData, setFormData ] = useState({});
    const [ messagePrompt, showMessage ] = useState('');
    const db = firebase.firestore();

    const updateInput = e => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    }

    const isValiEmail = (val) => {
        let regEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!regEmail.test(val)) {
          return false;
        } else {
            return true;
        }
    }

    const handleSubmit = event => {

        event.preventDefault();

        if(isValiEmail(formData.email) && formData.name.length>1 && formData.message.length>5) {
            sendEmail();
        }
        
    }

    const sendEmail = () => {
        db.collection('emails').doc(`${Date.now()}`).set({
            from: formData.email,
            replyTo: formData.email,
            to: ['hi@mondieki.com'],
            message: {
                subject: `${formData.name} messaged you.`,
                text: formData.message
            },
            name: formData.name,
            email: formData.email,
            messageSent: formData.message,
            time: new Date()
        })
        .then(() => {
            
            setFormData({
                name: '',
                email: '',
                message: ''
            });

            showMessage('Your message has been sent!');
            setTimeout(() => {
                showMessage('');
            }, 3000);
        })
        .catch(err => {
            showMessage('Your message was not sent. Please try again');
        });
    }


    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Contact | Mondieki </title>
                <link rel="canonical" href="https://mondieki.com/contact" />
            </Helmet>
            <section className="hero_warp inner_banner contact_banner">
                <div className="container">
                    <div className="row d-flex align-items-center">
                        <div className="col-md-12 col-12">
                            <div className="banner_content">
                                <h1 className="banner_title">Contact Mike</h1>
                                <p className="banner_para wow fadeInUp">I will help bring your vision to life.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <ul className="social_link">
                    <li>
                        <a href="https://www.linkedin.com/in/mondieki" aria-label="Mondieki's LinkedIn">
                            <i className="fa fa-linkedin"></i>
                            <i className="fa fa-linkedin"></i>
                        </a>
                    </li>
                    <li>
                        <a href="https://github.com/Mondieki" aria-label="Mondieki's GitHub">
                            <i className="fa fa-github"></i>
                            <i className="fa fa-github"></i>
                        </a>
                    </li>
                </ul>
            </section>

            <section className="somethings_interesting_wrap">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <h3 className="title text-center wow fadeInUp" data-wow-delay="0.2s">Have something interesting  in mind? Let's Talk</h3>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 col-md-4 col-sm-12 col-12"></div>
                        <div className="col-lg-4 col-md-4 col-sm-12 col-12">
                            <div className="interesting_item">
                                <div className="interesting_icon wow fadeInUp" data-wow-delay="0.6s">
                                    <img src="assets/images/svg/nairobi.png" alt="nairobi skyline"/>
                                </div>
                                <div className="interesting_content wow fadeInUp" data-wow-delay="0.8s">
                                    <h4>Nairobi Garage</h4>
                                    <p>Cove Court Watermark Business Park, Nairobi<br/>
                                        <a href="mailto:hi@mondieki.com"><span>hi@mondieki.com</span><span>hi@mondieki.com</span></a><br/>
                                        <a href="tel:+254112477455"><span>+254 (0) 112 477 455</span><span>+254 (0) 112 477 455</span></a> 
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 col-12"></div>
                    </div>
                </div>
            </section>

            <section className="contact_form_wrap">
                <div className="container">
                    <div className="row">
                        {/* <div className="col-12">
                            <h2 className="contact_form_title text-center wow fadeInUp" data-wow-delay="0.5s">Say Hello!</h2>
                        </div> */}
                        <div className="col-12">
                            <form className="contact_form" id="contactForm">
                                <div className="row">
                                    <div className="col-lg-6 col-md-6 col-sm-12 col-12 form-group wow fadeInUp" data-wow-delay="0.2s">
                                        <input type="text" name="name" className="form-control" placeholder="Name*" onChange={updateInput} value={formData.name || ''} required />
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-12 col-12 form-group wow fadeInUp" data-wow-delay="0.4s">
                                        <input type="text" name="email" className="form-control" placeholder="Email*" onChange={updateInput} value={formData.email || ''} required />
                                    </div>
                                    <div className="col-lg-12 col-md-12 col-sm-12 col-12 form-group wow fadeInUp" data-wow-delay="0.6s">
                                        <textarea className="form-control" name="message" placeholder="Message*" onChange={updateInput} value={formData.message || ''}></textarea>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-12 col-12 form-group wow fadeInUp" data-wow-delay="0.2s" onClick={handleSubmit}>
                                        <input type="submit" className="sibmit_btn" value="Send" />
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12 col-12 form-group">
                                    { messagePrompt }
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        
        </div>
    )
}

export default Contact